import React from 'react';
import { graphql } from 'gatsby';

import CustomLink from 'common/CustomLink';
import Card from 'components/Card';

import './StoreList.scss';

const StoreList = ({ sectionTitle, storeListCards }: PageContent.StoreListType) => (
  <section className="store-list" data-testid="storeList" id="main-content">
    <div className="store-list__title">
      <h2>{sectionTitle}</h2>
    </div>
    <div className="store-list__cards">
      {storeListCards.map((card) => (
        <Card
          className="card--small"
          topCardContent={{ cardImage: card }}
          bottomCardContent={{ title: card.title }}
          key={card.title}
        >
          {card.description}
          <CustomLink
            linkLabel={card.customLink.linkLabel}
            color={card.customLink.color}
            link={card.customLink.link}
            isLinkExternal={card.customLink.isLinkExternal}
          />
        </Card>
      ))}
    </div>
  </section>
);

export const ProductCategoryCardsType = graphql`
  fragment StoreListType on StoreListType {
    sectionTitle
    storeListCards {
      ...CardType
    }
  }
`;

export default StoreList;
