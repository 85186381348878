import React from 'react';
import { graphql } from 'gatsby';

import Container from 'layout/Container';
import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import SlimBanner from 'components/SlimBanner';
import StoreList from 'components/StoreList';

const BuyOnline = ({
  data: {
    buyOnline: { urls, seo, heroBanner, storeList, slimInfoBanner },
  },
  pageContext: {
    breadcrumb: { crumbs },
    lang,
  },
}: BuyOnlineTypes.BuyOnlineProps) => {
  const { title, keywords, description } = seo;
  const crumbsSettings = {
    crumbs,
    isDisplayedCrumbs: true,
  };

  return (
    <Layout {...{ crumbsSettings }} headerTransparent>
      <Seo {...{ lang, urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner {...heroBanner} />
      <Container>
        <StoreList {...storeList} />
      </Container>
      {slimInfoBanner ? <SlimBanner {...slimInfoBanner} /> : null}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $id: String) {
    buyOnline(lang: { eq: $lang }, id: { eq: $id }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      heroBanner {
        ...BannerType
      }
      storeList {
        ...StoreListType
      }
      slimInfoBanner {
        ...SlimBannerType
      }
    }
  }
`;

export default BuyOnline;
